<template>
  <EntitySingle :config="config" :entity.sync="entity" :loaded.sync="entityLoaded">
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="accountFormFields"
            allow-autofocus
          />
        </b-tab>

        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="personalFormFields"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'

import { isAdmin } from '@/auth/utils'
import { translationUtils } from '@core/mixins/ui/translations'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'

import storeModule from '../leadsStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
    BCard,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        participant: {},
        enabled: true,
      },
      entityLoaded: false,
      countryOptions: [],
      leadSourceOptions: [],
      categoryOptions: [],
      companyOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'lead',
        endpoint: 'lead',
        route: 'leads',
        title: {
          single: this.$t('Lead'),
          plural: this.$t('Leads'),
        },
      }
    },
    isAdmin() {
      return isAdmin()
    },

    accountFormFields() {
      const formFields = [
        {
          id: 'companies',
          type: 'selectmultiple',
          label: this.$t('Companies'),
          options: this.companyOptions,
          colSize: 12,
          hidden: !this.isAdmin,
        },
        {
          id: 'leadSource',
          object: true,
          type: 'select',
          label: this.$t('Lead Source'),
          options: this.leadSourceOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'firstName',
          parent: 'participant',
          type: 'text',
          label: this.$t('First Name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'lastName',
          parent: 'participant',
          type: 'text',
          label: this.$t('Last Name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'email',
          parent: 'participant',
          type: 'email',
          label: this.$t('email'),
          rules: 'email',
          colSize: 3,
        },
        {
          id: 'categories',
          object: true,
          type: 'selectmultiple',
          label: this.$t('Categories'),
          options: this.categoryOptions,
          required: false,
          colSize: 6,
        },
        {
          id: 'telephone',
          parent: 'participant',
          type: 'text',
          label: this.$t('telephone'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'mobile',
          parent: 'participant',
          type: 'text',
          label: this.$t('mobile'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'utmMedium',
          type: 'text',
          label: this.$t('UTM Medium'),
          colSize: 3,
        },
        {
          id: 'utmSource',
          type: 'text',
          label: this.$t('UTM Source'),
          colSize: 3,
        },
        {
          id: 'utmCampaign',
          type: 'text',
          label: this.$t('UTM Campaign'),
          colSize: 3,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('Enabled'),
          colSize: 3,
        },
        {
          id: 'comments',
          type: 'quill',
          label: this.$t('comments'),
          rules: 'min:3',
          colSize: 12,
        },
      ]
      return formFields
    },
    personalFormFields() {
      return [
        {
          id: 'country',
          object: true,
          parent: 'participant',
          type: 'select',
          label: this.$t('country'),
          options: this.countryOptions,
          colSize: 3,
        },
        {
          id: 'city',
          parent: 'participant',
          type: 'text',
          label: this.$t('city'),
          colSize: 3,
        },
        {
          id: 'address',
          parent: 'participant',
          type: 'text',
          label: this.$t('address'),
          colSize: 3,
        },
        {
          id: 'postalCode',
          parent: 'participant',
          type: 'text',
          label: this.$t('postal code'),
          colSize: 3,
        },
        {
          id: 'birthDate',
          parent: 'participant',
          type: 'date',
          label: this.$t('birth date'),
          colSize: 3,
        },
        {
          id: 'companyName',
          parent: 'participant',
          type: 'text',
          label: this.$t('company name'),
          colSize: 3,
        },
        {
          id: 'map',
          type: 'map',
          colSize: 12,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchLeadSources').then(response => {
      this.leadSourceOptions = response.data
    })
    this.$store.dispatch('app-common/fetchLeadCategories').then(response => {
      this.categoryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCompanies').then(response => {
      this.companyOptions = response.data
    })
  },
}
</script>
